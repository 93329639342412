<template>
  <div class="m-spot-item-list">
    <base-link
      class="m-spot-item-list__link text-decoration-none"
      :link="spotItem.link ? spotItem.link.url : null"
      :target="spotItem.link ? spotItem.link.target : null"
    >
      <base-image
        v-if="spotItem.image && spotItem.image[0]"
        :url="spotItem.image[0].url"
        :width="443"
        :height="424"
        :ratio="443/424"
        :alt-text="spotItem.imageAltText"
        image-class="m-spot-item-list__image"
      />
      <div
        v-if="spotItem.firstPublishDate && datedPageTypes.includes(spotItem.ncContentTypeAlias)"
        class="m-spot-item-list__date"
      >
        {{ publishDate }}
      </div>
      <div class="m-spot-item-list__text">
        <h3
          v-if="$isNotNullOrUndefined(spotItem.headline)"
          class="m-spot-item-list__headline"
        >
          {{ spotItem.headline }}
        </h3>
        <p
          v-if="$isNotNullOrUndefined(spotItem.subline)"
          class="m-spot-item-list__subline mb-2"
        >
          {{ spotItem.subline }}
        </p>
        <p
          v-if="$isNotNullOrUndefined(spotItem.text)"
          class="m-spot-item-list__intro mb-3"
          v-html="spotItem.text"
        />
        <span v-if="spotItem.link && spotItem.link.name" class="m-spot-item-list__cta">
          {{ spotItem.link.name }}
          <base-icon icon="chevron-right" />
        </span>
      </div>
    </base-link>
  </div>
</template>

<script>
import * as dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/da';
import 'dayjs/locale/sv';
import BaseLink from '@/components/atoms/BaseLink';
import BaseImage from '@/components/atoms/BaseImage';
import BaseIcon from '@/components/atoms/BaseIcon';

export default {
  name: 'SpotItem',
  components: {
    BaseLink,
    BaseImage,
    BaseIcon
  },
  props: {
    spotItem: {
      default () {
        return {};
      },
      required: true,
      type: Object
    }
  },
  data () {
    return {
      datedPageTypes: ['blog', 'event', 'contentPage']
    }
  },
  computed: {
    culture () {
      return this.$store.getters['content/content/getContent']?.meta?.culture;
    },
    publishDate () {
      let culture = this.culture.split('-')[0];
      let date = dayjs(this.spotItem.firstPublishDate).locale(culture).format('dddd [d.] D/M - YYYY');
      return date;
    }
  }
};
</script>
